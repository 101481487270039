// @flow
import * as React from 'react';

import Dropdown from 'components/ui/Dropdown';
import I18N from 'lib/I18N';
import Icon from 'components/ui/Icon';
import { DATA_UPLOAD_DISABLED_DEPLOYMENTS } from 'components/DataUploadApp';
import { ENABLED_DATA_CATALOG_APP } from 'components/DataCatalogApp/flags';
import { IS_ZENYSIS_USER } from 'util/util';
import {
  asDropdownOption,
  asButton,
  localizeUrl,
  onLinkClicked,
} from 'components/Navbar/util';
import type { DropdownOptionEventHandler } from 'components/Navbar/util';

const DEPLOYMENT = window.__JSON_FROM_BACKEND.deploymentName;

const { enableEntityMatching } = window.__JSON_FROM_BACKEND.ui;

export type Locale = {
  /** The country flag e.g. 🇺🇬 */
  flag: string,

  /** The locale ISO code, e.g. 'en', 'am' */
  id: string,

  /** The locale name e.g. English */
  label: string,

  /** The localized url  */
  url: string,
};

type Props = {
  children?:
    | ((
        options: React.ChildrenArray<
          React.Element<Class<Dropdown.Option<DropdownOptionEventHandler>>>,
        >,
      ) => React.Element<Class<Dropdown<DropdownOptionEventHandler>>>)
    | React.Node,

  /** Boolean value to determine whether a user is an admin or not */
  isAdmin?: boolean,

  /** Boolean value to determine whether a user is authenticated or not */
  isAuthenticated: boolean,

  /** Boolean value to determine whether to return links as drop down options */
  linksAsDropdownOptions?: boolean,

  /** Supported languages and their details */
  locales?: $ReadOnlyArray<Locale>,

  /** Boolean value to determine whether to show Catalog Setup link */
  showCatalogSetup: boolean,

  /** Boolean value to determine whether to show Data Catalog link */
  showDataCatalog: boolean,

  /** Boolean value to determine whether to show Data Upload link */
  showDataUpload: boolean,

  /** Boolean value to determine whether to show Data Catalog link */
  showEntityMatching: boolean,

  /** Boolean value to determine whether to show locales or not  */
  showLocales?: boolean,
};

export default function MoreLinks({
  isAuthenticated,
  children = null,
  isAdmin = false,
  showCatalogSetup = false,
  showDataUpload = false,
  showDataCatalog = false,
  showEntityMatching = false,
  showLocales = false,
  locales = [],
  linksAsDropdownOptions = true,
}: Props): React.Node {
  const maybeRenderAdminOption = React.useMemo(() => {
    if (!isAdmin) {
      return null;
    }
    const onAdminLinkClick = e =>
      onLinkClicked(localizeUrl('/admin'), e, 'Admin navigation link clicked');
    const text = I18N.text('Admin');

    if (linksAsDropdownOptions) {
      return asDropdownOption(
        onAdminLinkClick,
        text,
        'glyphicon glyphicon-cog',
      );
    }

    return asButton(onAdminLinkClick, text);
  }, [isAdmin, linksAsDropdownOptions]);

  const maybeRenderLocaleOptions = React.useMemo(() => {
    if (!showLocales) {
      return null;
    }

    return locales.map((locale: Locale): React.Node => {
      const onLocaleLinkClick = e =>
        onLinkClicked(
          locale.url,
          e,
          `Locale ${locale.id} navigation link clicked`,
        );

      const text = locale.label;
      if (linksAsDropdownOptions) {
        return asDropdownOption(
          onLocaleLinkClick,
          '',
          `flag flag-${locale.flag}`,
          <span>{text}</span>,
        );
      }
      return asButton(onLocaleLinkClick, text);
    });
  }, [linksAsDropdownOptions, locales, showLocales]);

  const maybeRenderUserManualLink = React.useMemo(() => {
    const { userManualUrl } = window.__JSON_FROM_BACKEND.ui;
    if (!userManualUrl) {
      return null;
    }
    const onUserManualLinkClick = e =>
      onLinkClicked(userManualUrl, e, 'User manual accessed', {
        nonInteraction: 1,
      });
    const text = I18N.text('User Manual');
    if (linksAsDropdownOptions) {
      return asDropdownOption(
        onUserManualLinkClick,
        text,
        'glyphicon glyphicon-book',
      );
    }
    return asButton(onUserManualLinkClick, text);
  }, [linksAsDropdownOptions]);

  const maybeRenderDataStatusOption = React.useMemo(() => {
    if (!isAdmin || DEPLOYMENT === 'mz') {
      // HACK(ian): Temporary while mz and mz-covid config/pipeline are not
      // separated.
      return null;
    }
    const onDataStatusLinkClick = e =>
      onLinkClicked(
        localizeUrl('/data-status'),
        e,
        'Data status navigation link clicked',
      );
    const text = I18N.text('Data Status');

    if (linksAsDropdownOptions) {
      return asDropdownOption(
        onDataStatusLinkClick,
        text,
        'glyphicon glyphicon-hdd',
      );
    }
    return asButton(onDataStatusLinkClick, text);
  }, [isAdmin, linksAsDropdownOptions]);

  const maybeRenderGeoMapperOption = React.useMemo(() => {
    // TODO(camden): Switch to config or postgres for this
    if (
      ![
        'afsa',
        'beyond_zero',
        'gates_malaria',
        'pk',
        'za',
        'ke',
        'tz',
      ].includes(DEPLOYMENT)
    ) {
      return null;
    }

    const onGeoMapperLinkClick = e =>
      onLinkClicked(
        localizeUrl('/geo-mapping'),
        e,
        'GIS navigation link clicked',
      );

    const text = I18N.text('Map Builder');
    const betaTag = ['gates_malaria', 'pk'].includes(DEPLOYMENT) ? (
      <sup className="navbar-dropdown-menu__beta">
        <I18N>beta</I18N>
      </sup>
    ) : null;

    return linksAsDropdownOptions
      ? asDropdownOption(
          onGeoMapperLinkClick,
          text,
          'glyphicon glyphicon-map-marker',
          betaTag,
        )
      : asButton(onGeoMapperLinkClick, text, false, betaTag);
  }, [linksAsDropdownOptions]);

  const maybeRenderDataUploadOption = React.useMemo(() => {
    if (!showDataUpload || DATA_UPLOAD_DISABLED_DEPLOYMENTS.has(DEPLOYMENT)) {
      return null;
    }

    const onDataUploadLinkClick = e =>
      onLinkClicked(
        localizeUrl('/data-upload'),
        e,
        'Data upload navigation link clicked',
      );

    const text = I18N.text('Data Upload');

    return linksAsDropdownOptions
      ? asDropdownOption(
          onDataUploadLinkClick,
          text,
          'glyphicon glyphicon-upload',
        )
      : asButton(onDataUploadLinkClick, text);
  }, [showDataUpload, linksAsDropdownOptions]);

  const maybeRenderDataCatalogOption = React.useMemo(() => {
    if (!ENABLED_DATA_CATALOG_APP || !showDataCatalog) {
      return null;
    }
    const onDataCatalogLinkClick = e =>
      onLinkClicked(
        localizeUrl('/data-catalog'),
        e,
        'Data catalog navigation link clicked',
      );
    const text = I18N.text('Data Catalog');
    if (linksAsDropdownOptions) {
      return asDropdownOption(
        onDataCatalogLinkClick,
        text,
        'glyphicon glyphicon-folder-close',
      );
    }
    return asButton(onDataCatalogLinkClick, text);
  }, [showDataCatalog, linksAsDropdownOptions]);

  const maybeRenderDataDigestOption = React.useMemo(() => {
    // Since data digest is internal only, limit viewers to zenysis emails.
    // HACK(abby): Allow bao admins as well for the Hawaii response.
    const canViewDataDigest =
      IS_ZENYSIS_USER || (isAdmin && DEPLOYMENT === 'bao');
    if (!canViewDataDigest) {
      return null;
    }

    const onDataDigestLinkClick = e =>
      onLinkClicked(
        localizeUrl('/data-digest'),
        e,
        'Data digest navigation link clicked',
      );

    const text = I18N.text('Data Digest');

    return linksAsDropdownOptions
      ? asDropdownOption(
          onDataDigestLinkClick,
          text,
          'glyphicon glyphicon-list-alt',
        )
      : asButton(onDataDigestLinkClick, text);
  }, [isAdmin, linksAsDropdownOptions]);

  const maybeRenderCatalogSetupOption = React.useMemo(() => {
    if (!showCatalogSetup) {
      return null;
    }
    const onCatalogSetupClick = e =>
      onLinkClicked(
        localizeUrl('/catalog-setup'),
        e,
        'Catalog setup navigation link clicked',
      );
    const text = I18N.textById('Catalog Setup');
    if (linksAsDropdownOptions) {
      return asDropdownOption(
        onCatalogSetupClick,
        text,
        'glyphicon glyphicon-folder-close',
      );
    }
    return asButton(onCatalogSetupClick, text);
  }, [linksAsDropdownOptions, showCatalogSetup]);

  const maybeRenderEntityMatchingOption = React.useMemo(() => {
    if (!showEntityMatching || !enableEntityMatching) {
      return null;
    }
    const onEntityMatchingClick = e =>
      onLinkClicked(
        localizeUrl('/entity-matching'),
        e,
        'Entity Matching navigation link clicked',
      );
    const text = I18N.text('Entity Matching');
    if (linksAsDropdownOptions) {
      return (
        <Dropdown.Option key={text} value={onEntityMatchingClick}>
          <span className="navbar-dropdown-menu__icon">
            <Icon type="svg-entity-matching-navbar" />
          </span>
          {text}
        </Dropdown.Option>
      );
    }
    return asButton(onEntityMatchingClick, text);
  }, [showEntityMatching, linksAsDropdownOptions]);

  const renderSignOutOption = React.useCallback(
    (logoutUrl: string, username: string) => {
      const onSignOutLinkClick = e =>
        onLinkClicked(logoutUrl, e, 'User logged out', { username });
      const text = I18N.text('Sign out');
      if (linksAsDropdownOptions) {
        return asDropdownOption(
          onSignOutLinkClick,
          text,
          'glyphicon glyphicon-log-out',
        );
      }
      return asButton(onSignOutLinkClick, text);
    },
    [linksAsDropdownOptions],
  );

  const renderAuthenticatedOptions = () => {
    const { logoutUrl, username } = window.__JSON_FROM_BACKEND.user;
    return [
      maybeRenderAdminOption,
      maybeRenderDataCatalogOption,
      maybeRenderUserManualLink,
      maybeRenderDataStatusOption,
      maybeRenderDataUploadOption,
      maybeRenderDataDigestOption,
      maybeRenderCatalogSetupOption,
      maybeRenderLocaleOptions,
      maybeRenderGeoMapperOption,
      maybeRenderEntityMatchingOption,
      renderSignOutOption(logoutUrl, username),
    ];
  };

  const renderNonAuthenticatedOptions = React.useCallback(
    (loginUrl: string) => {
      const wrapper = linksAsDropdownOptions ? asDropdownOption : asButton;
      return wrapper(e => onLinkClicked(loginUrl, e), I18N.text('Sign in'));
    },
    [linksAsDropdownOptions],
  );

  const { loginUrl } = window.__JSON_FROM_BACKEND.user;

  const unAuthenticatedOptions = [
    renderNonAuthenticatedOptions(loginUrl),
    maybeRenderLocaleOptions,
  ];

  const options = isAuthenticated
    ? renderAuthenticatedOptions()
    : unAuthenticatedOptions;

  if (!children || !linksAsDropdownOptions || typeof children !== 'function') {
    return options;
  }

  // if the links are dropdowns options, we need to pass them to a function
  // that can render them in a dropdown
  // $FlowFixMe[incompatible-call]
  return children(options);
}
