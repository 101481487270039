// @flow
import * as Zen from 'lib/Zen';
import Dimension from 'models/core/wip/Dimension';
import LinkedCategory from 'models/core/wip/LinkedCategory';

type RequiredValues = {
  category: LinkedCategory,
  dimensions: Zen.Array<Dimension>,
  id: string,
  sourceName: string | void,
};

type DefaultValues = {
  +constituentIds: $ReadOnlyArray<string>,
  +description: string,
};

class FieldMetadata extends Zen.BaseModel<
  FieldMetadata,
  RequiredValues,
  DefaultValues,
> {
  static defaultValues: DefaultValues = {
    constituentIds: [],
    description: '',
  };
}

export default ((FieldMetadata: $Cast): Class<Zen.Model<FieldMetadata>>);
