// @flow
import type { VisibilityStatus } from 'models/core/DataCatalog/constants';

export const DIMENSION_TYPE = 'DIMENSION';
export const FIELD_TYPE = 'FIELD';
export type ElementType = 'DIMENSION' | 'FIELD';

export type ItemType = 'CATEGORY' | ElementType;

export type DimensionCategoryMapping = {
  categoryId: string,
  visibilityStatus: VisibilityStatus,
};

export type DimensionMapping = $ReadOnly<{
  code: string,
  description: string,
  hasCopies: boolean,
  id: string,
  isGeoDimension: boolean,
  name: string,
  parentCategoryId: string,
  visibilityStatus: VisibilityStatus,
}>;

export type DimensionCategory = $ReadOnly<{
  id: string,
  isEmpty: boolean,
  name: string,
  visibilityStatus: VisibilityStatus,
}>;

export type FieldCategory = $ReadOnly<{
  id: string,
  name: string,
}>;
