// @flow
import Promise from 'bluebird';

import APIService, { API_VERSION } from 'services/APIService';
import Dimension from 'models/core/wip/Dimension';
import DimensionService, {
  FullDimensionService,
} from 'services/wip/DimensionService';
import type { APIVersion, HTTPService } from 'services/APIService';

type UnallowedDimensions = Set<string>;
type ServicePromise = Promise<UnallowedDimensions>;

class AuthorizedDimensionService {
  _activePromise: ServicePromise | void;
  _httpService: HTTPService;
  _unallowedDimensions: UnallowedDimensions;
  apiVersion: APIVersion = API_VERSION.V2;

  constructor(httpService: HTTPService) {
    this._httpService = httpService;
  }

  buildListOfUnallowedDimensions(): ServicePromise {
    if (this._unallowedDimensions) {
      return new Promise(resolve => resolve(this._unallowedDimensions));
    }
    if (this._activePromise) {
      return this._activePromise;
    }
    this._activePromise = this._httpService
      .get(this.apiVersion, `${DimensionService.endpoint}/unauthorized`)
      .then(unallowedDimensions => {
        this._activePromise = undefined;
        this._unallowedDimensions = new Set(unallowedDimensions);
        return this._unallowedDimensions;
      });
    return this._activePromise;
  }

  /**
   * Retrieve all values from the cache.
   *
   * returns: Promise<Array<T>>
   */
  getAll(includeHiddenDimension: boolean): Promise<$ReadOnlyArray<Dimension>> {
    return Promise.all([
      includeHiddenDimension
        ? FullDimensionService.getAll()
        : DimensionService.getAll(),
      this.buildListOfUnallowedDimensions(),
    ]).then(([dimensions, disallowedDimensions]) => {
      return disallowedDimensions
        ? dimensions.filter(
            dimension => !disallowedDimensions.has(dimension.dimensionCode()),
          )
        : dimensions;
    });
  }
}

export default (new AuthorizedDimensionService(
  APIService,
): AuthorizedDimensionService);
